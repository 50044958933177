import React from "react";

import _move from "../lib/move";
import * as utils from "../lib/utils";
import * as paymentsUtils from "@pcloud/web-utilities/dist/payments/utils.payments";
import * as sharedUtils from "@pcloud/web-utilities/dist/lib/utils";
import * as errors from "@pcloud/web-utilities/dist/errors/errorsTranslationKeys";
import { initCrashlytics } from "@pcloud/web-utilities/dist/lib/crashlyticsReports"
import * as constants from "@pcloud/web-utilities/dist/config/constants";
import * as constantsConfig from "@pcloud/web-utilities/dist/config";
import * as constantsPlans from "@pcloud/web-utilities/dist/plans/constants.plans";
import * as constantsPayments from "@pcloud/web-utilities/dist/payments/constants.payments";
import * as plansinfo from "@pcloud/web-utilities/dist/plans/plansInfo";
import * as plansConstants from "@pcloud/web-utilities/dist/plans/constants.plans";
import * as plansUtils from "@pcloud/web-utilities/dist/plans/utils.plans";
import * as plansPrices from "@pcloud/web-utilities/dist/plans/prices";
import * as paymentsConstants from "@pcloud/web-utilities/dist/payments/constants.payments";
import * as payments from "../lib/payments"
import * as discountmethods from "../lib/discountMethods"
import diffs from "../lib/diffs"
import * as api from "@pcloud/web-utilities/dist/api/passApi";
import componentMount from "../lib/componentMount"


const _attachList = [
  {
    attach: self,
    list: [
      //Payments
      "providers",
      "storePurchaseDataLocally",
      "getPurchaseData",

      //Utils
      "hrefSearch",
      "removeHrefSearch",
      "addHrefSearch",
      "signToCurrency",
      "currencyMap",
      "formatMS",
      "msToTime",
      "cleanFloat",
      "renderQuota",
      "detectBrowser",
      "isValidEmailAddress",
      "apiCall",
      "apiCallPost",
      "getCookiesFromSibling",
      "getLanguage",
      "countriesInEuropeList",
      "isCountryInEurope",
      "encodeQueryData",
      "debounce",
      "loadScript",

      //Plans Info
      "currencyPrices",
      "businessPrices",
      "productNameMap",
      "getPlanName",
      "productInfo",
      "getTotalStorage",
      "getTotalTraffic",
      "getCustomPlanStorageCount",
      "getCurrentStorageCount",
      "getUserTtrafficCount",
      "planBoxPeriodTexts",
      "socialProofPlanInfo",
      "planNames",
      "testPeriods",
      "plansQuota",
      "PASS_PREMIUM_PLANS",

      //Discount Methods
      "checkIncomingDiscount",
      "priceAfterDiscount",

      //Diffs
      "diffs",

      //Payments Constants
      "PAYMENT_DISRUPTION_COUNTRIES",

      //Constants
      "ERRORS_TRANSLATION_KEYS",

      //Plans Constants
      "ADDITIONAL_TRAFFIC_ID",
      "BUSINESS_PLAN_ID",
      "BUSINESS_PLAN_2_ID",
      "BUSINESS_PLANS_ID_MAP",
      "PREMIUM_PLAN_ID",
      "PREMIUM_PLUS_PLAN_ID",
      "PRO_PLAN_ID",
      "ULTRA_PLAN_ID",
      "CRYPTO_PLAN_ID",
      "CUSTOM_PLAN_ID",
      "CUSTOM4TB_PLAN_ID",
      "CUSTOM_20COUNT_PLAN_ID",
      "CUSTOM_8COUNT_PLAN_ID",
      "ADD_STORAGE_1COUNT_PLAN_ID",
      "ADD_STORAGE_4COUNT_PLAN_ID",
      "ADD_STORAGE_20COUNT_PLAN_ID",
      "MAX_CUSTOM_TRAFFIC_COUNT",
      "MAX_CUSTOM_STORAGE_COUNT",
      "TB_PER_TRAFFIC_COUNT",
      "PASS_PREMIUM_PLAN_ID",
      "PASS_FAMILY_PLAN_ID",
      "FAMILY2TB_PLAN_ID",
      "FAMILY_CUSTOM_PLAN_ID",
      "FAMILY_CUSTOM_2COUNT_PLAN_ID",
      "FAMILY_CUSTOM_5COUNT_PLAN_ID",
      "FAMILY_ADD_1COUNT_PLAN_ID",
      "FAMILY_ADD_5COUNT_PLAN_ID",
      "gaPeriods",
      "PASS_PLANS_PER_PERIOD",
      "STORAGE_PLANS",
      "PASS_PLANS",

      //Discount Methods
      "checkIncomingDiscount",
      "priceAfterDiscount",

      //Diffs
      "diffs",

      //Constants
      "PAYMENT_DISRUPTION_COUNTRIES",
      "ERRORS_TRANSLATION_KEYS",

      //Api
      "passApiMethod",

      //web-utilities
      "initCrashlytics",
    ]
  }
]

const prepComponents = {};

_move(utils, _attachList);
_move(paymentsUtils, _attachList);
_move(sharedUtils, _attachList);
_move(plansinfo, _attachList);
_move(plansUtils, _attachList);
_move(plansPrices, _attachList);
_move(paymentsConstants, _attachList);
_move(payments, _attachList);
_move(discountmethods, _attachList);
_move(constants, _attachList);
_move(constantsConfig, _attachList);
_move(constantsPlans, _attachList);
_move(constantsPayments, _attachList);
_move(plansConstants, _attachList);
_move(errors, _attachList);
_move(api, _attachList);
_move({ diffs }, _attachList);
_move(prepComponents, _attachList);
_move({ initCrashlytics }, _attachList);