//@flow

import { DiscountType, Userinfo, PlanIDType } from "../lib/types/dataTypes.components";
import {
  areArraysEqual
} from "./utils";

type checkIncomingDiscountParams = {
  discountCode: string,
  onSucces: (discount: DiscountType) => void,
  onError: () => void,
  auth?: string
};

export const checkIncomingDiscount = ({
  discountCode = "",
  onSuccess = () => {},
  onError = () => {},
  auth = ""
}: checkIncomingDiscountParams) => {
  var dfd = jQuery.Deferred();
  const params = {
    'discountcode': discountCode
  };

  if (auth) {
    params['auth'] = auth;
  }

  pCloudGlobals.apiMethod('getdiscountcodeinfo', params, (data) => {
    if (data.discountcode && !data.isUsed && (typeof data.remaining === "undefined" || data.remaining > 0)) {
      const dataCopy = { ...data };
      const code = { ...data.discountcode };
  
      if (dataCopy.discountcode.productdiscount) {
        const discountProductsArr = code.productdiscount;
        const discountProductsObj = {};

        for (let i = 0; i < discountProductsArr.length; i++) {
          discountProductsObj[discountProductsArr[i].productid] = discountProductsArr[i];
          discountProductsObj[discountProductsArr[i].productid].monthdiscount;
        }
        code.productdiscount = discountProductsObj;
      }

      dataCopy.discountcode = code;

      onSuccess(dataCopy);
      dfd.resolve(dataCopy);
    } else {
      onError && onError(data); 
      dfd.reject(data)
    }
  }, {
    errorCallback: (error) => {
      onError && onError(error);
      dfd.reject(error);
    }
  });

  return dfd;
};

type isDiscountAllowedParams = {
  discount: DiscountType,
  user: Userinfo,
  forPlan: PlanIDType,
  forPeriod: "month" | "year" | "lifetime"
};

export const isDiscountAllowed = ({
  discount,
  user,
  forPlan,
  forPeriod
}: isDiscountAllowedParams) => {
  let isValidForProduct = false;
  let isValidForPeriod = false;
  let userHasDiscount =
    user && user.lastsubscription && user.lastsubscription.discountcode;
  let result = false;

  if (discount.discountcode && forPlan && forPeriod) {
    if (discount.discountcode.isbundle) {
      isValidForProduct = areArraysEqual(forPlan, discount.discountcode.products);
    } else {
      isValidForProduct = discount.discountcode.products.some(
        productID => forPlan == productID
      );
    }

    isValidForPeriod = discount.discountcode.periods.some(
      period => forPeriod == period
    );

    result = isValidForProduct && isValidForPeriod;
  }

  if (
    userHasDiscount &&
    !discount.discountcode.lifetimediscount &&
    forPeriod !== "lifetime"
  ) {
    result = false;
  }

  if (discount.isUsed) {
    result = false;
  }

  return result;
};

export const priceAfterDiscount = (price: number, discountPercent: number) => {
  const result = price - price * (discountPercent / 100);
  const decimal = result % 1 == 0 ? 0 : 2;

  return Number(result.toFixed(decimal));
};
